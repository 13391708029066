/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */

import "./AjaxDialog";
import "./ContentTools.less";

(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	var contentToolsInit = false;
	var contentToolsReinit = false;
	var editor;
	var translations;
	var callbackQueue = [];
	var saveError = false;
	var fileImageUploadUrl = false;
	var fileImageRotateUrl = false;
	var fileImageCropAndSaveUrl = false;

	function _initClass(scope) {
		_init();
		_reinit();

		$Self("form[data-content-editable-form-saveonsubmit]", scope).getUninitializedOnly("G8.Contenttools.formSaveonsubmit").bind("submit", _sbmt_formsave);
		$Self("*[data-content-addable] a", scope).getUninitializedOnly("G8.Contenttools.addable").bind("click", _clk_addable);
		$Self("*[data-content-editable-controls-internal]", scope).getUninitializedOnly("G8.Contenttools.moveup").bind("click", _clk_setinternal);
		$Self("*[data-content-editable-controls-up]", scope).getUninitializedOnly("G8.Contenttools.moveup").bind("click", _clk_moveup);
		$Self("*[data-content-editable-controls-down]", scope).getUninitializedOnly("G8.Contenttools.movedown").bind("click", _clk_movedown);
		$Self("*[data-content-editable-controls-delete]", scope).getUninitializedOnly("G8.Contenttools.delete").bind("click", _clk_delete);
		$Self("*[data-content-editable-inputs]", scope).getUninitializedOnly("G8.Contenttools.inputs").bind("change", _chg_form);
	}

	function _reinit() {
		if (contentToolsInit) {
			if (editor.isEditing())
				editor.stop(true);
			if (contentToolsReinit)
				editor.destroy();
			editor.init("*[data-content-editable]", "data-content-id");
			editor.start();
			window.onbeforeunload = function () {
				$.ajaxSetup({async: false});
				_pushToQueueSaveAndExecute(function () {
					editor.stop();
				});
				if (saveError)
					return saveError;
			};
			contentToolsReinit = true;
		}
	}

	function _init() {
		if (!contentToolsInit && $("*[data-content-editable]").length) {
			translations = window._jsTranslations["Contenttools"];

			ContentTools.DEFAULT_TOOLS = [
				[
					"bold",
					"italic",
					"align-left",
					"align-center",
					"align-right"
				], [
					"subheading",
					"paragraph",
					"unordered-list",
					"ordered-list",
					"table",
					"line-break",
					"indent",
					"unindent"
				], [
					"undo",
					"redo",
					"remove"
				]
			];
			ContentTools.HIGHLIGHT_HOLD_DURATION = 1000000000;

			ContentEdit.addTranslations("de", translations);
			ContentEdit.LANGUAGE = "de";

			ContentTools.StylePalette.add([
				new ContentTools.Style(ContentEdit._("Headline 1"), "hl-1", ["h2"]),
				new ContentTools.Style(ContentEdit._("Headline 2"), "hl-2", ["h2"]),
				new ContentTools.Style(ContentEdit._("Headline 3"), "hl-3", ["h2"]),
				new ContentTools.Style(ContentEdit._("Headline Special"), "hl-special", ["h2"])
			]);

			ContentTools.IMAGE_UPLOADER = imageUploader;

			editor = ContentTools.EditorApp.get();

			editor.addEventListener("start", _start);
			editor.addEventListener("saved", _saved);
			editor.addEventListener("stop", _stop);

			contentToolsInit = true;

			fileImageUploadUrl = $("*[data-content-page-imageuploadurl]").data("contentPageImageuploadurl");
			fileImageRotateUrl = $("*[data-content-page-imagerotateurl]").data("contentPageImagerotateurl");
			fileImageCropAndSaveUrl = $("*[data-content-page-imagecropandsaveurl]").data("contentPageImagecropandsaveurl");

//			var modal = new ContentTools.ModalUI();
//			var dialog = new ContentTools.AjaxDialog("TEST");
//			editor.attach(modal);
//			editor.attach(dialog);
//			modal.show();
//			dialog.show();
		}
	}

	function _chg_form(e) {
		var container = $(this);
		var updateUrl = container.data("contentEditableInputsPagenodeUrl");
		var updateId = container.data("contentEditableInputsPagenodeId");

		var data = {data: [{id: updateId, value: {}}]};
		container.find("input,select").each(
			function () {
				var input = $(this);
				if (input.attr("name"))
					data["data"][0]["value"][input.attr("name")] = input.val();
				else
					data["data"][0]["value"] = input.val();
			}
		);

		$.ajax({
			"type": "POST",
			"url": updateUrl,
			"data": data,
			"error": function (jqXHR, textStatus, errorThrown) {
				alert(jqXHR.responseJSON.message);
			},
			"dataType": "json"
		});
	}

	function _clk_delete(e) {
		e.preventDefault();

		if (!confirm(ContentEdit._("Do you really want to delete this item?")))
			return false;

		var control = $(this);
		var container = control.closest("*[data-content-editable-container]");

		_pushToQueueSaveAndExecute(function () {
			$.ajax({
				type: "GET",
				url: control.attr("href"),
				success: function (data, textStatus, jqXHR) {
					container.remove();
				},
				error: function (jqXHR, textStatus, errorThrown) {
					alert(jqXHR.responseJSON.message);
				},
				complete: function () {

				},
				dataType: "json"
			});
		});

		return false;
	}

	function _clk_setinternal(e) {
		e.preventDefault();
		var control = $(this);
		var currInternal = control.data("contentEditableControlsInternalValue");
		var newInternal = currInternal ? 0 : 1;

		$.ajax({
			type: "GET",
			url: control.attr("href") + "/" + newInternal,
			success: function (data, textStatus, jqXHR) {
				if (data.return.success) {
					control.data("contentEditableControlsInternalValue", newInternal);
					if (newInternal)
						control.addClass("act");
					else
						control.removeClass("act");
				}
			},
			error: function (jqXHR, textStatus, errorThrown) {
				alert(jqXHR.responseJSON.message);
			},
			complete: function () {

			},
			dataType: "json"
		});

		return false;
	}

	function _clk_moveup(e) {
		e.preventDefault();
		_move($(this), -1);
		return false;
	}

	function _clk_movedown(e) {
		e.preventDefault();
		_move($(this), 1);
		return false;
	}

	function _move(control, delta) {
		var container = control.closest("*[data-content-editable-container]");
		if (delta == -1)
			var insertContainer = container.prev("*[data-content-editable-container]");
		else// if(delta == 1)
			var insertContainer = container.next("*[data-content-editable-container]");

		if (insertContainer.length)
			$.ajax({
				type: "GET",
				url: control.attr("href"),
				success: function (data, textStatus, jqXHR) {
					if (data.return.success) {
						if (delta == -1)
							container.insertBefore(insertContainer);
						else// if(delta == 1)
							container.insertAfter(insertContainer);
					}
				},
				error: function (jqXHR, textStatus, errorThrown) {
					alert(jqXHR.responseJSON.message);
				},
				complete: function () {

				},
				dataType: "json"
			});
	}

	function _clk_addable(e) {
		e.preventDefault();

		var submit = $(this);
		var addable = submit.closest("*[data-content-addable]");
		var select = addable.find("select");

		if (select.val()) {
			_pushToQueueSaveAndExecute(function () {
				$.ajax({
					type: "POST",
					url: select.val(),
					success: function (data, textStatus, jqXHR) {
						$(data).insertBefore(addable).parent().trigger("domChange");
					},
					error: function (jqXHR, textStatus, errorThrown) {
						alert(jqXHR.responseJSON.message);
					},
					complete: function () {

					},
					dataType: "html"
				});
			});
		}

		return false;
	}

	function _sbmt_formsave(e) {
		e.preventDefault();
		var form = $(this);

		_pushToQueueSaveAndExecute(function () {
			form.unbind("submit", _sbmt_formsave);
			form.trigger("submit");
		});

		return false;
	}

	function _pushToQueueSaveAndExecute(callback) {
		if (contentToolsInit) {
			callbackQueue.push(callback);
			editor.save(true);
		} else
			callback();
	}

	function _afterSaveExecuteCallbackQueue() {
		for (var i in callbackQueue)
			callbackQueue[i]();
		callbackQueue = [];
	}

	function _start(e) {
		editor.autoSaveTimer = setInterval(_autoSave, 30 * 1000);
		editor.highlightRegions(true);
	}

	function _stop(e) {
		clearInterval(editor.autoSaveTimer);
		editor.highlightRegions(false);
	}

	function _autoSave() {
		editor.save(true);
	}

	function _saved(e) {
		saveError = false;
		var passive = e.detail().passive;

		var regions = e.detail().regions;
		if (Object.keys(regions).length == 0) {
			_afterSaveExecuteCallbackQueue();
			return;
		}

		editor.busy(true);

		var post = {};
		var postIdx = 0;
		for (name in regions) {
			if (name.search("pagenode_") != -1) {
				post["data[" + postIdx + "][id]"] = name.replace("pagenode_", "");
				post["data[" + postIdx + "][value]"] = regions[name];
				postIdx++;
			} else
				$(name).val(regions[name]);
		}

		if (postIdx > 0) {
			$.ajax({
				type: "POST",
				url: "/cms/editPagenodes",
				data: post,
				success: function (data, textStatus, jqXHR) {
				},
				error: function (jqXHR, textStatus, errorThrown) {
					alert(jqXHR.responseJSON.message);
					saveError = jqXHR.responseJSON.message;
				},
				complete: function () {
					editor.busy(false);
					_afterSaveExecuteCallbackQueue();
				},
				dataType: "json"
			});
		} else {
			editor.busy(false);
			_afterSaveExecuteCallbackQueue();
		}
	}

	function imageUploader(dialog) {
		var image, xhr, xhrComplete, xhrProgress;

		dialog.addEventListener("imageuploader.cancelupload", function () {
			if (xhr) {
				xhr.upload.removeEventListener("progress", xhrProgress);
				xhr.removeEventListener("readystatechange", xhrComplete);
				xhr.abort();
			}

			dialog.state("empty");
		});

		dialog.addEventListener("imageuploader.clear", function () {
			dialog.clear();
			image = null;
		});

		dialog.addEventListener("imageuploader.fileready", function (ev) {
			var formData;
			var file = ev.detail().file;

			xhrProgress = function (ev) {
				dialog.progress((ev.loaded / ev.total) * 100);
			};

			xhrComplete = function (ev) {
				var response;

				if (ev.target.readyState != 4)
					return;

				xhr = null;
				xhrProgress = null;
				xhrComplete = null;

				if (parseInt(ev.target.status) == 200) {
					response = JSON.parse(ev.target.responseText);
					response = response["return"];

					image = {
						size: response.dimensions,
						url: response.url,
						id: response.id
					};

					dialog.populate(image.url, image.size);
				} else {
					response = JSON.parse(ev.target.responseText);
					alert(response.message);
				}
			};

			dialog.state("uploading");
			dialog.progress(0);

			formData = new FormData();
			formData.append("file", file);

			// Make the request
			xhr = new XMLHttpRequest();
			xhr.upload.addEventListener("progress", xhrProgress);
			xhr.addEventListener("readystatechange", xhrComplete);
			xhr.open("POST", fileImageUploadUrl, true);
			xhr.setRequestHeader("Accept", "application/json");
			xhr.send(formData);
		});

		function rotateImage(direction) {
			var formData;

			xhrComplete = function (ev) {
				var response;

				if (ev.target.readyState != 4)
					return;

				xhr = null;
				xhrComplete = null;

				dialog.busy(false);

				if (parseInt(ev.target.status) == 200) {
					response = JSON.parse(ev.target.responseText);
					response = response["return"];

					image = {
						size: response.dimensions,
						url: response.url + "?_ignore=" + Date.now(),
						id: response.id
					};

					dialog.populate(image.url, image.size);
				} else {
					response = JSON.parse(ev.target.responseText);
					alert(response.message);
				}
			};

			dialog.busy(true);

			formData = new FormData();
			formData.append("data[Diskfile][id]", image.id);
			formData.append("data[Diskfile][direction]", direction);

			xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", xhrComplete);
			xhr.open("POST", fileImageRotateUrl, true);
			xhr.setRequestHeader("Accept", "application/json");
			xhr.send(formData);
		}

		dialog.addEventListener("imageuploader.rotateccw", function () {
			rotateImage("ccw");
		});

		dialog.addEventListener("imageuploader.rotatecw", function () {
			rotateImage("cw");
		});

		dialog.addEventListener("imageuploader.save", function () {
			var crop, cropRegion, formData;

			xhrComplete = function (ev) {
				if (ev.target.readyState !== 4)
					return;

				xhr = null;
				xhrComplete = null;

				dialog.busy(false);

				if (parseInt(ev.target.status) === 200) {
					response = JSON.parse(ev.target.responseText);
					response = response["return"];

					image = {
						size: [820, 820 * (response.dimensions[1] / response.dimensions[0])],
						url: response.url,
						id: response.id
					};

					dialog.save(
						image.url + "?_ignore=" + Date.now(),
						image.size, {
							"alt": "",
							"data-ce-max-width": image.size[0],
							"data-ce-max-height": image.size[1],
							"data-diskfileid": image.id
						});
				} else {
					response = JSON.parse(ev.target.responseText);
					alert(response.message);
				}
			};

			dialog.busy(true);

			formData = new FormData();
			formData.append("data[Diskfile][id]", image.id);
			if (dialog.cropRegion())
				formData.append("data[Diskfile][crop]", dialog.cropRegion());

			xhr = new XMLHttpRequest();
			xhr.addEventListener("readystatechange", xhrComplete);
			xhr.open("POST", fileImageCropAndSaveUrl, true);
			xhr.setRequestHeader("Accept", "application/json");
			xhr.send(formData);
		});
	}

	return {};

})();