/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */
import "./Files.less";

(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	var isUploading = false;

	function _initClass(scope) {
		$Self("a[data-file-delete]", scope).getUninitializedOnly("G8.File.delete").bind("click", function (e) {
			e.preventDefault();
			e.stopPropagation();

			if (!confirm(ContentEdit._("Do you really want to delete this file?")))
				return false;

			var link = $(this);
			var container = link.closest("*[data-file-delete-container]");

			if (link.attr("href") != "javascript:;")
				$.ajax({
					type: "GET",
					url: link.attr("href"),
					success: function (data, textStatus, jqXHR) {
						container.remove();
					},
					error: function (jqXHR, textStatus, errorThrown) {
						alert(jqXHR.responseJSON.message);
					},
					complete: function () {

					},
					dataType: "json"
				});
			else
				container.remove();

			return false;
		});

		$Self("a[data-file-editname]", scope).getUninitializedOnly("G8.File.editname").bind("click", function (e) {
			e.preventDefault();
			e.stopPropagation();

			var link = $(this);
			var container = link.closest("*[data-file-editname-container]");
			var display = container.find("*[data-file-editname-display]");
			var displayAttr = container.filter("*[data-file-editname-display-attr]");

			var name = prompt(ContentEdit._("Please enter a new filename."), display.text());
			if (name == null)
				return;

			$.ajax({
				type: "GET",
				url: link.attr("href"),
				data: {name: name},
				success: function (data, textStatus, jqXHR) {
					display.text(name);
					displayAttr.attr(displayAttr.data("fileEditnameDisplayAttr"), name);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					alert(jqXHR.responseJSON.message);
				},
				complete: function () {

				},
				dataType: "json"
			});

			return false;
		});

		$Self("*[data-file-upload]", scope).getUninitializedOnly("G8.File.upload").each(function () {
			var container = $(this);
			var uploadUrl = container.data("fileUploadUrl");
			var onlyPdf = container.data("fileUploadOnlypdf");
			onlyPdf = onlyPdf ? true : false;
			var drop = container.find("*[data-file-upload-drop]");
			var choose = container.find("*[data-file-upload-choose]");
			var replace = container.closest("*[data-file-upload-outer-container]").find("*[data-file-upload-replace]");
			var innerSaveHandling = container.data("fileUploadInnerSaveHandling");
			var innerSaveHandlingTable = false;
			var innerSaveHandlingTemplate = false;
			if (innerSaveHandling) {
				innerSaveHandlingTable = container.closest("*[data-file-upload-inner-save-handling-table]");
				innerSaveHandlingTemplate = innerSaveHandlingTable.find("*[data-file-upload-inner-save-handling-template]");
			}

			var _helper_handleFiles = function (e, handleFileUploadUrl) {
				e.preventDefault();
				e.stopPropagation();

				if (isUploading)
					return;

				var filesSize = 0;
				var filesQueue = [];
				var filesCount = 0;
				var filesUploadedCount = 0;
				var files = e.originalEvent.target.files || e.originalEvent.dataTransfer.files;
				for (var i = 0; i < files.length; i++) {
					var file = files[i];
					var ext = file.name.substr(file.name.lastIndexOf(".") + 1);
					if (onlyPdf && ext != "pdf" && ext != "PDF")
						alert(ContentEdit._("The following file isn´t a PDF: ") + file.name);
					else {
						filesCount++;
						filesSize += file.size;
						filesQueue.push(file);
					}
				}

				if (filesCount == 0)
					return;

				isUploading = true;
				drop.addClass("dragover");
				drop.addClass("uploading");
				var progressBar = $("<div class=\"progressBar\"><div class=\"progressBarInner\"></div></div>").appendTo(drop);

				_upload(innerSaveHandling, innerSaveHandlingTable, innerSaveHandlingTemplate, handleFileUploadUrl, filesSize, filesQueue, filesCount, filesUploadedCount, progressBar, function () {
					progressBar.remove();
					isUploading = false;
					drop.removeClass("dragover");
					drop.removeClass("uploading");
					container.trigger("pagenodeReload");
				});
			};

			var _handleFiles = function (e) {
				_helper_handleFiles(e, uploadUrl);
			};

			choose.bind("change", _handleFiles);
			drop.bind("drop", _handleFiles);
			drop.bind("dragover", function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (isUploading)
					return;

				drop.addClass("dragover");
			});
			drop.bind("dragleave", function (e) {
				e.preventDefault();
				e.stopPropagation();

				if (isUploading)
					return;

				drop.removeClass("dragover");
			});

			if (replace.length)
				replace.each(function () {
					var replaceInput = $(this);
					var replaceUrl = replaceInput.data("fileUploadReplaceUrl");
					replaceInput.bind("change", function (e) {
						var replaceNamePrompt = replaceInput.data("fileUploadReplaceNamePrompt");
						var keepNameOnReplace = 0;
						if (replaceNamePrompt)
							keepNameOnReplace = confirm(replaceNamePrompt) ? 1 : 0;
						_helper_handleFiles(e, replaceUrl + "/" + keepNameOnReplace);
					});
				});
		});
	}

	function _upload(innerSaveHandling, innerSaveHandlingTable, innerSaveHandlingTemplate, uploadUrl, filesSize, filesQueue, filesCount, filesUploadedCount, progressBar, complete, progressStartWidth, currChunkedFile, currChunkNum) {
		progressStartWidth = progressStartWidth ? progressStartWidth : 0;

		var file;
		if (isNaN(currChunkNum)) {
			file = filesQueue.shift();
			filesUploadedCount++;
			currChunkNum = 1;
			currChunkedFile = file;
		} else {
			file = currChunkedFile;
			currChunkNum++;
		}

		var chunkSize = 2097152;
		var chunkCount = Math.max(Math.ceil(file.size / chunkSize), 1);
		var currChunkSize = currChunkNum < chunkCount ? chunkSize : (file.size - (chunkSize * (currChunkNum - 1)));

		var percentOfTotalFilesize = (currChunkSize / filesSize) * 100;
		var xhrProgress = function (ev) {
			progressBar.find(".progressBarInner").css("width", (progressStartWidth + ((ev.loaded / ev.total) * percentOfTotalFilesize)) + "%");
		};

		var xhrComplete = function (ev) {
			var response;

			if (ev.target.readyState != 4)
				return;

			if (parseInt(ev.target.status) == 200) {
				response = JSON.parse(ev.target.responseText);
				response = response["return"];
			} else {
				response = JSON.parse(ev.target.responseText);
				alert(response.message);
			}

			if (currChunkNum == chunkCount) {
				if (filesUploadedCount == filesCount)
					complete();
				else
					_upload(innerSaveHandling, innerSaveHandlingTable, innerSaveHandlingTemplate, uploadUrl, filesSize, filesQueue, filesCount, filesUploadedCount, progressBar, complete, progressStartWidth + percentOfTotalFilesize);

				if (innerSaveHandling) {
					var tableRowHtmlHelper = $("<div></div>");
					innerSaveHandlingTemplate.clone().appendTo(tableRowHtmlHelper);
					var tableRowHtml = tableRowHtmlHelper.html();
					var _fileSize = response.bytesize;
					if (_fileSize < 1024)
						_fileSize = _fileSize.toString() + " B";
					else if (_fileSize < 1048576) {
						_fileSize = _fileSize / 1024.0;
						_fileSize = _fileSize.toFixed(1) + " KB";
					} else {
						_fileSize = _fileSize / (1024.0 * 1024.0);
						_fileSize = _fileSize.toFixed(2) + " MB";
					}
					tableRowHtml = tableRowHtml
						.replace(/___NAME___/g, response.name)
						.replace(/___ID___/g, response.id)
						.replace(/___ORIGFILENAME___/g, response.origfilename)
						.replace(/___EXT___/g, response.ext)
						.replace(/___SIZE___/g, _fileSize)
						.replace(/___BYTESIZE___/g, response.bytesize)
						.replace(/data-file-upload-inner-save-handling-template="true"/g, "");
					innerSaveHandlingTemplate.parent().append(tableRowHtml);
					innerSaveHandlingTable.parent().trigger("domChange");
					
				}
			} else
				_upload(innerSaveHandling, innerSaveHandlingTable, innerSaveHandlingTemplate, uploadUrl, filesSize, filesQueue, filesCount, filesUploadedCount, progressBar, complete, progressStartWidth + percentOfTotalFilesize, currChunkedFile, currChunkNum);
		};

		var sliceFunction = (file.slice ? "slice" : (file.mozSlice ? "mozSlice" : "webkitSlice"));
		var fileByteStart = chunkSize * (currChunkNum - 1);
		var fileByteEnd = fileByteStart + chunkSize;
		fileByteEnd = fileByteEnd > file.size ? file.size : fileByteEnd;
		var formData = new FormData();
		var blob = file[sliceFunction](fileByteStart, fileByteEnd);
		formData.append("file", blob);
		formData.append("fileName", file.name);
		formData.append("fileChunk", (currChunkNum - 1));
		formData.append("fileChunks", chunkCount);

		const xhr = new XMLHttpRequest();
		xhr.upload.addEventListener("progress", xhrProgress);
		xhr.addEventListener("readystatechange", xhrComplete);
		xhr.open("POST", uploadUrl, true);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.send(formData);
	}

	return {};

})();