/*
 * Copyright 2021 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2021.09.08 at 16:00
 */

(function( ) {
    G8Framework.addGlobalListener("domChange", _initClass);
    
    function _initClass(scope) {
        $Self("*[data-sublease-src]", scope).getUninitializedOnly("G8.Advertisement.Sublease").each(function (e) {
            $(this)
                .bind('change', function(e) {
                    let dst = $(this).closest("form").find("*[data-sublease-dst]").parent();
                    if($(this).val() == 1)
                        dst.show();
                    else
                        dst.hide();
                })
                .trigger('change');
        });
    }
    
    return {};
    
})();