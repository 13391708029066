/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 *
 */
import datepicker from 'js-datepicker'
import 'js-datepicker/dist/datepicker.min.css';

(function( ) {
	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {
		$Self("*[data-datepicker]", scope).getUninitializedOnly("G8.DatePicker").each(function (e) {
			const $input = $(this);
			const input = $input[0]
			const now = new Date();
			const startDate = new Date();

			let maxDate = null;
			maxDate = new Date();
			maxDate.setDate(startDate.getDate() + 6 * 7 + 1);
			
			let dateValTemp = null;
			if(input.value)
			{
				dateValTemp = input.value.split(".");
				dateValTemp = new Date(dateValTemp[2], dateValTemp[1]-1, dateValTemp[0]);
				input.value = '';
			}
			
			if(dateValTemp < startDate)
				startDate = dateValTemp;
			
			if($input.data('maxDate')) {
				input.disabled = maxDate < now;
				const picker = datepicker(input, {
					dateSelected: dateValTemp,
					formatter: (input, date, instance) => {
						date.setTime(date.getTime() + (4*60*60*1000));
						input.value = date.toLocaleDateString('de-DE', {year: "numeric", month: "2-digit", day: "2-digit"}).slice(0, 10);
					},
					respectDisabledReadOnly: maxDate < now
				});
				picker.setMin(startDate);
				picker.setMax(maxDate);
			}
			else {
				const picker = datepicker(input, {
					dateSelected: dateValTemp,
					formatter: (input, date, instance) => {
						date.setTime(date.getTime() + (4*60*60*1000));
						input.value = date.toLocaleDateString('de-DE', {year: "numeric", month: "2-digit", day: "2-digit"}).slice(0, 10);
					}
				});
				picker.setMin(startDate);
			}
		});
	}

	return {};

})();