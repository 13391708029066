/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */

import "./Fancybox.less";
import "./jquery.fancybox.pack";

(function () {

	G8Framework.addGlobalListener("domChange", _initClass);

	function _initClass(scope) {
		$Self("*[data-fancybox]", scope)/*.getUninitializedOnly("G8.Fancybox")*/.each(function () {
			var images = $(this).find("*[data-fancybox-href]").not("[data-fancybox-href*='___ID___']");
			images.fancybox({
				type: "image",
				padding: 0
			});
		});
	}

	return {};

})();